<template>
  <BaseBonusPanel :id="bonus.id" type="deposit" :timer="bonusTimer">
    <template #title> {{ titleByLevel }} </template>
    <template #subtitle>
      <div class="bonus-text">
        <template
          v-for="(subBonus, index) in filteredMultiBonuses"
          :key="index"
        >
          <span v-if="isStepBonus">{{ t('bonuses.toAmount') }}</span>
          <span>
            {{ subBonus?.amount }}
          </span>
          <StIcon
            v-if="subBonus?.icon"
            :name="subBonus.icon"
            :size="16"
            class="icon"
          />
          <span v-if="subBonus?.text">
            {{ subBonus?.text }}
          </span>
          <span v-if="index + 1 < filteredMultiBonuses.length"> + </span>
        </template>
      </div>
    </template>
    <template #actions>
      <StButton
        :label="t('bonuses.depositPanelMakeDeposit')"
        size="m"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useDepositBonus } from '../../../composables/useDepositBonus'
import type { DepositBonus } from '../../../types'

const { bonus } = defineProps<{
  bonus: DepositBonus
}>()

const { t } = useI18n()

const { filteredMultiBonuses, titleByLevel, isStepBonus } = useDepositBonus(
  computed(() => bonus),
)

const bonusTimer = computed(() => ({
  title: t('bonuses.timerTitle'),
  expiresAt: bonus.expiredAt ?? '',
}))
</script>

<style scoped>
.bonus-text {
  overflow: hidden;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    margin-left: var(--spacing-025);
    color: var(--text-warning);
  }
}

.icon {
  min-width: 14px;
  margin: calc(var(--spacing-025) * -1) var(--spacing-025);
}
</style>
