<template>
  <BaseBonusPanel
    v-if="!isExpired"
    :id="bonus.id"
    :type="bonus.type"
    :timer="bonusTimer"
  >
    <template #title>
      {{ title }}
    </template>
    <template #subtitle>
      <p
        class="subtitle"
        :class="{ success: bonus.status === 'readyToClaim' }"
        data-t="subtitle"
      >
        <img
          v-if="isHiddenAmount"
          :src="spoilerImg"
          alt="spoiler"
          width="28"
          height="14"
        />
        <span v-else>{{ bonusAmount }}</span>
        <StIcon :name="currencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        size="m"
        class="button"
        :type="bonus.status === 'readyToClaim' ? 'primary' : 'ghost'"
        :disabled="bonus.status === 'waitingForAccrual'"
        :loading="claimStatus === 'pending'"
        @click="onBonusSubmit"
      >
        {{ buttonTitle }}
        <ClientOnly>
          <StLottie
            v-if="claimStatus === 'success'"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </StButton>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useRegularBonus } from '../../RegularBonusCard/useRegularBonus'
import spoilerImg from '../../../assets/spoiler.svg'
import type { RegularBonus } from '../../../stores/useRegularBonusesStore'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'

interface Props {
  bonus: RegularBonus
}

const { bonus } = defineProps<Props>()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'claim'): void
}>()

const {
  handleBonusButtonClick,
  claimStatus,
  currencyIcon,
  bonusAmount,
  claimAnimation,
  isHiddenAmount,
  bonusTimer,
  isExpired,
} = useRegularBonus(computed(() => bonus))

async function onBonusSubmit() {
  await handleBonusButtonClick()
  /* для проигрыша анимации */
  await delay(500)
  emit('claim')
}

const { getTitle } = useGetTitleByLevel()
const title = computed(() => {
  switch (bonus.type) {
    case 'cashback':
      return t('bonuses.weeklyCashback')
    case 'rakeBack':
      return t('bonuses.rakeback')
    case 'reload':
      return getTitle(t('bonuses.reload'), bonus.level)
    case 'ratRace':
      return t('bonuses.ratRace')
    default:
      return ''
  }
})

const buttonTitle = computed(() => {
  if (bonus.type === 'reload') {
    return t('bonuses.take')
  }

  switch (bonus.status) {
    case 'readyToClaim':
      return t('bonuses.take')
    case 'waitingForAccrual':
      return t('bonuses.goToGame')
    default:
      return t('bonuses.goToGame')
  }
})
</script>

<style scoped>
.title {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.subtitle {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.success {
    color: var(--text-warning);
  }
}

.countdown {
  min-width: 60px;
}

.button {
  position: relative;
}

.animation {
  position: absolute;
  top: -12px;
  overflow: visible;
}
</style>
